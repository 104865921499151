@import url('https://fonts.googleapis.com/css?family=El+Messiri|Tajawal&display=swap');
@import url('https://fonts.googleapis.com/css?family=David+Libre&display=swap');

root, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
}

//body {
//  max-width: 800px;
//  margin-right: auto;
//  margin-left: auto;
//}

.arabic-font {
  font-family: 'Tajawal', sans-serif;
}

.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}

//.fc .fc-button {
//  display: inline-block;
//  font-weight: 400;
//  text-align: center;
//  vertical-align: middle;
//  -webkit-user-select: none;
//  user-select: none;
//  background-color: transparent;
//  border: 1px solid transparent;
//  padding: 0.4em 0.65em;
//  font-size: 12px;
//  line-height: 1.5;
//  border-radius: 0.25em;
//}//todo fullCalendar CSS

.hebrew-font *:not(.material-icons) {
  font-family: 'David Libre', serif !important;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.MuiList-padding {
  padding-top: 1px !important;
}

/*TODO: maybe use another stable solution instead*/
.MuiGrid-spacing-xs-0,
.MuiGrid-spacing-xs-1,
.MuiGrid-spacing-xs-2,
.MuiGrid-spacing-xs-3,
.MuiGrid-spacing-xs-4,
.MuiGrid-spacing-xs-5,
.MuiGrid-spacing-xs-6,
.MuiGrid-spacing-xs-7,
.MuiGrid-spacing-xs-9,
.MuiGrid-spacing-xs-9,
.MuiGrid-spacing-xs-10 {
  width: 100% !important;
  margin: 0 !important;
}

/*TODO: maybe use react media hook instead*/
@media only screen and (max-height: 600px) {
  .dore-form {
    height: auto !important;
  }
  .MuiGrid-spacing-xs-10 > .MuiGrid-item {
    padding: 25px !important;
  }
  .dore-footer {
    display: none; //TODO: maybe should not hidden it
  }
}

@media (min-width: 600px) { //TODO: Temp Solution
  .MuiToolbar-gutters {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
}